import { Controller } from 'stimulus'
import { MDCTextField } from '@material/textfield'
import { MDCSelect } from '@material/select'
import { MDCFormField } from '@material/form-field'
import { MDCRadio } from '@material/radio'
import { MDCCheckbox } from '@material/checkbox'
import { MDCRipple } from '@material/ripple'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.mdc-text-field').forEach((element) => {
      MDCTextField.attachTo(element)
    })
    this.element.querySelectorAll('.mdc-select').forEach((element) => {
      MDCSelect.attachTo(element)
    })
    this.element.querySelectorAll('.mdc-radio').forEach((element) => {
      const radio = MDCRadio.attachTo(element)
      const field = MDCFormField.attachTo(element.parentElement)
      field.input = radio
    })
    this.element.querySelectorAll('.mdc-checkbox').forEach((element) => {
      const checkbox = MDCCheckbox.attachTo(element)
      const field = MDCFormField.attachTo(element.parentElement)
      field.input = checkbox
    })
    this.element.querySelectorAll('.mdc-button').forEach((element) => {
      MDCRipple.attachTo(element)
    })
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input' ]

  change(event) {
    this.inputTarget.value = event.detail.value
    this.inputTarget.dataset.changed = 'true'
  }
}

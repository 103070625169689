import { Controller } from 'stimulus'
import { MDCDialog } from '@material/dialog'

export default class extends Controller {
  static targets = [ 'dialog']

  connect() {
    this.dialog = new MDCDialog(this.dialogTarget)
  }

  open() {
    this.dialog.open()
  }
}

import { Controller } from 'stimulus'
import { MDCTextField } from '@material/textfield'

export default class extends Controller {
  static targets = [ 'item', 'list' ]

  add() {
    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    const clone = lastItem.cloneNode(true)

    clone.dataset.index = this.itemTargets.length.toString()
    clone.dataset.cloned = 'yes'

    this.listTarget.appendChild(clone)
  }

}

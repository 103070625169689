import { Controller } from 'stimulus'
import { MDCTextField } from '@material/textfield'
import { MDCFormField } from '@material/form-field'
import { MDCCheckbox } from '@material/checkbox'

export default class extends Controller {
  static targets = [ 'item', 'list' ]

  add() {
    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    const clone = lastItem.cloneNode(true)
    const textareaExp = RegExp('textarea', 'i')

    clone.querySelectorAll('input, textarea').forEach((element) => {
      const name = element.name
      if (name) {
        element.name = name.replace(/\[choices_attributes\]\[(\d+)\]/, (_, number) => `[choices_attributes][${parseInt(number) + 1}]` )
      }

      const id = element.id
      if (id) {
        element.id = id.replace(/_choices_attributes_(\d+)/, (_, number) => `_choices_attributes_${parseInt(number) + 1}` )
      }

      if (textareaExp.test(element.tagName)) {
        element.value = ''
      }
    })

    this.listTarget.appendChild(clone)

    clone.querySelectorAll('.mdc-text-field').forEach((element) => {
      MDCTextField.attachTo(element)
    })

    clone.querySelectorAll('.mdc-checkbox').forEach((element) => {
      const checkbox = MDCCheckbox.attachTo(element)
      const field = MDCFormField.attachTo(element.parentElement)
      field.input = checkbox
      checkbox.checked = false
    })
  }
}

import { Controller } from 'stimulus'
import { MDCTextField } from '@material/textfield'
import { MDCFormField } from '@material/form-field'
import { MDCCheckbox } from '@material/checkbox'
import { MDCRadio } from '@material/radio'
import { MDCSelect } from '@material/select'
import { MDCRipple } from '@material/ripple'

export default class extends Controller {
  static targets = [ 'item', 'list' ]

  add() {
    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    const clone = lastItem.cloneNode(true)
    const textareaExp = RegExp('textarea', 'i')
    const choiceIdExp = /\[choices_attributes\]\[\d+\]\[id\]/
    const stimulusIdExp = /\[stimulus_attributes\]\[id\]/
    const audioIdExp = /\[audio_attributes\]\[id\]/
    const fileLabelExp = /{{{.+}}}/

    clone.querySelectorAll('.cms-will-deleted').forEach((element) => {
      element.remove()
    })

    clone.querySelectorAll('input, textarea').forEach((element) => {
      const name = element.name
      if (name) {
        if (choiceIdExp.test(name) || stimulusIdExp.test(name) || audioIdExp.test(name)) {
          element.remove()
        } else {
          element.name = name.replace(/\[questions_attributes\]\[(\d+)\]/, (_, number) => `[questions_attributes][${parseInt(number) + 1}]` )
        }
      }

      const id = element.id
      if (id) {
        element.id = id.replace(/_questions_attributes_(\d+)/, (_, number) => `_questions_attributes_${parseInt(number) + 1}` )
      }
      // remove textare content
      if (textareaExp.test(element.tagName)) {
        element.value = ''
      }
      // remove filenames
      if (fileLabelExp.test(element.value)) {
        element.value = ''
      }
      // uncheck radio and checkbox inputs
      const inputType = element.getAttribute('type')
      if (inputType == 'checkbox' || inputType == 'radio') {
        element.removeAttribute('checked')
      }
    })

    clone.querySelectorAll('.mdc-select[data-for]').forEach((element) => {
      const name = element.dataset.for
      element.dataset.for = name.replace(/\[questions_attributes\]\[(\d+)\]/, (_, number) => `[questions_attributes][${parseInt(number) + 1}]` )
    })

    clone.querySelectorAll('label').forEach((element) => {
      const id = element.getAttribute('for')
      if (id) {
        element.setAttribute('for', id.replace(/_questions_attributes_(\d+)/, (_, number) => `_questions_attributes_${parseInt(number) + 1}` ))
      }
    })

    const number = clone.querySelector('[data-attr="number"]')
    number.innerText = this.itemTargets.length + 1

    this.listTarget.appendChild(clone)

    clone.querySelectorAll('.mdc-text-field').forEach((element) => {
      MDCTextField.attachTo(element)
    })
    clone.querySelectorAll('.mdc-select').forEach((element) => {
      MDCSelect.attachTo(element)
    })
    clone.querySelectorAll('.mdc-radio').forEach((element) => {
      const radio = MDCRadio.attachTo(element)
      const field = MDCFormField.attachTo(element.parentElement)
      field.input = radio
      radio.checked = false
    })
    clone.querySelectorAll('.mdc-checkbox').forEach((element) => {
      const checkbox = MDCCheckbox.attachTo(element)
      const field = MDCFormField.attachTo(element.parentElement)
      field.input = checkbox
      checkbox.checked = false
    })
    clone.querySelectorAll('.mdc-button').forEach((element) => {
      MDCRipple.attachTo(element)
    })
  }
}

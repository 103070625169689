import { Controller } from 'stimulus'
import { FormUtil } from './form-util'
import { MDCTextField } from '@material/textfield'
import { MDCList } from '@material/list'

export default class extends Controller {
  static targets = [ 'number', 'search', 'value', 'definition', 'partOfSpeech', 'example', 'menu', 'list' ]

  connect() {
    this.list = new MDCList(this.listTarget)
    this.list.singleSelection = true
    this.entries = []

    if (this.element.dataset.cloned) {
      delete this.element.dataset.cloned

      this.definitionTarget.textContent = ''
      this.partOfSpeechTarget.textContent = ''
      this.valueTarget.value = ''
      this.searchTarget.value = ''

      const number = parseInt(this.element.dataset.index) + 1
      this.numberTarget.textContent = number

      this.element.querySelectorAll('.mdc-text-field').forEach((element) => {
        MDCTextField.attachTo(element)
      })

      this.element.querySelectorAll('input').forEach((element) => {
        FormUtil.setIndexForIdsAndNames(element, 'dictionary_entry_records_attributes')
      })
    }
  }

  select(event) {
    const data = this.list.listElements[event.detail.index].dataset

    this.searchTarget.value = data.subject
    this.partOfSpeechTarget.textContent = data.partOfSpeech
    this.definitionTarget.textContent = data.definition
    this.exampleTarget.textContent = data.example
    this.valueTarget.value = data.id

    this.menuTarget.classList.remove('cms-surface--open')
  }

  async lookup(event) {
    const value = event.target.value
    if (value.length < 2) {
      this.menuTarget.classList.remove('cms-surface--open')
      return
    }

    const response = await fetch(`/dictionary_entries.json?finder[subject]=${value}`)
    const json = await response.json()

    this.listTarget.querySelectorAll('li').forEach((li) => { li.remove() })

    json.forEach((data) => {
      const li = document.createElement('li')
      li.classList.add('mdc-list-item')
      li.setAttribute('role', 'menuitem')
      li.dataset.id = data['id']
      li.dataset.subject = data['subject']
      li.dataset.partOfSpeech = data['part_of_speech']
      li.dataset.definition = data['definition']
      li.dataset.example = data['example']

      const span = document.createElement('span')
      span.classList.add('mdc-list-item__text')

      const primary = document.createElement('span')
      primary.classList.add('mdc-list-item__primary-text')
      primary.textContent = data['subject']

      const secondary = document.createElement('span')
      secondary.classList.add('mdc-list-item__secondary-text')
      secondary.textContent = `${data['id']}(${data['part_of_speech']}) ${data['definition']}`

      span.appendChild(primary)
      span.appendChild(secondary)
      li.appendChild(span)
      this.listTarget.appendChild(li)
    })

    const rect = this.searchTarget.getBoundingClientRect()

    this.menuTarget.style.left = `${rect.left + document.body.scrollLeft}px`
    this.menuTarget.style.top = `${this.distanceFromTop(this.searchTarget) + this.searchTarget.clientHeight + 2}px`
    this.menuTarget.classList.add('cms-surface--open')
  }

  distanceFromTop(element) {
    var distance = 0, current = element

    do {
      distance += current.offsetTop
      current = current.offsetParent
    } while (current)

    return distance
  }
}

import { Controller } from 'stimulus'
import { MDCTextField } from '@material/textfield'
import { MDCFormField } from '@material/form-field'
import { MDCCheckbox } from '@material/checkbox'

export default class extends Controller {
  static targets = [ 'item', 'list' ]
  connect(){
    this.itemTargets.forEach((item, idx) => {
      item.querySelector('[data-attr="number"]').innerText = idx + 1
    })
  }

  add() {
    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    const clone = lastItem.cloneNode(true)

    clone.querySelectorAll('input, textarea').forEach((element) => {
      const name = element.name
      if (name) {
        element.name = name.replace(/\[entries_attributes\]\[(\d+)\]/, (_, number) => `[entries_attributes][${parseInt(number) + 1}]` )
      }

      const id = element.id
      if (id) {
        element.id = id.replace(/_entries_attributes_(\d+)/, (_, number) => `_entries_attributes_${parseInt(number) + 1}` )
      }

      element.value = ''
      
    })
    const number = clone.querySelector('[data-attr="number"]')
    number.innerText = this.itemTargets.length + 1

    this.listTarget.appendChild(clone)

    clone.querySelectorAll('.mdc-text-field').forEach((element) => {
      MDCTextField.attachTo(element)
    })

  }
}

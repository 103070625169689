import { Controller } from 'stimulus'
import {MDCIconButtonToggle} from '@material/icon-button'

export default class extends Controller {
  static targets = [ 'input', 'button' ]

  connect() {
    MDCIconButtonToggle.attachTo(this.buttonTarget)
  }

  toggle(event) {
    const isOn = event.detail.isOn
    this.inputTarget.value = isOn ? '1' : '0'
    this.element.classList.toggle('cms-will-deleted', isOn)

    this.element.querySelectorAll('textarea, input, button').forEach((element) => {
      if (element != this.buttonTarget && element != this.inputTarget) {
        element.disabled = isOn
      }
    })
    this.element.querySelectorAll('.mdc-radio').forEach((element) => {
      element.classList.toggle('mdc-radio--disabled', isOn)
    })
    this.element.querySelectorAll('.mdc-text-field').forEach((element) => {
      element.classList.toggle('mdc-text-field--disabled', isOn)
    })
    this.element.querySelectorAll('.mdc-checkbox').forEach((element) => {
      element.classList.toggle('mdc-checkbox--disabled', isOn)
    })
  }
}

import { Controller } from 'stimulus'
import { MDCTextField } from '@material/textfield'
import { MDCTextFieldIcon } from '@material/textfield/icon'
import * as SparkMD5 from 'spark-md5'

export default class extends Controller {
  connect() {
    const field = MDCTextField.attachTo(this.element)
    const file = this.element.querySelector('input[type="file"]')
    const text = this.element.querySelector('input[type="text"]')
    const icon = this.element.querySelector('.mdc-text-field__icon')

    file.addEventListener('focus', () => field.focus() )

    file.addEventListener('change', (event) => {
      if (event.target.files && event.target.files[0]) {
        const fileobj = event.target.files[0]
        const filesize = fileobj.size
        const buffer = 1024*1024 // 1MB
        const steps = Math.ceil(filesize / buffer)

        const slice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
        const spark = new SparkMD5.ArrayBuffer()
        const reader = new FileReader()

        var current = 0

        reader.onload = function (e) {
          spark.append(e.target.result)
          current++

          if (current < steps) {
            read()
          } else {
            complete()
          }
        }

        function complete() {
          const checksum = btoa(spark.end(true)).replace(/\W/,'').substring(0, 8)
          const filename = fileobj.name.replace(/\.(\w+)$/, `_${checksum}.$1`)
          const filetype = fileobj.type.split('/').shift()
          text.value = `${filename}`
          icon.classList.remove('dn')
        }

        function read() {
          const start = current * buffer
          const end = ((start + buffer) >= filesize) ? filesize : start + buffer
          reader.readAsArrayBuffer(slice.call(fileobj, start, end))
        }

        read()
      } else {
        icon.classList.toggle('dn', !text.value)
      }
    })

    icon.classList.toggle('dn', !text.value)
  }
}

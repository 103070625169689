// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'
import { MDCTextField } from '@material/textfield';
import { MDCRipple } from '@material/ripple';

export default class extends Controller {
  static targets = [ 'email', 'password', 'button' ]

  connect() {
    MDCTextField.attachTo(this.emailTarget)
    MDCTextField.attachTo(this.passwordTarget)
    MDCRipple.attachTo(this.buttonTarget)
  }
}

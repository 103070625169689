import { Controller } from 'stimulus'
import { MDCTopAppBar } from '@material/top-app-bar'
import { MDCDrawer } from '@material/drawer'
import { MDCRipple } from '@material/ripple'

export default class extends Controller {
  static targets = [ 'drawer', 'menu' ]

  connect() {
    const drawer = MDCDrawer.attachTo(this.drawerTarget)
    const menu = MDCTopAppBar.attachTo(this.menuTarget)

    this.menuTarget.querySelectorAll('.mdc-button').forEach((element) => {
      MDCRipple.attachTo(element)
    })

    menu.listen('MDCTopAppBar:nav', () => {
      drawer.open = !drawer.open
    })
  }
}

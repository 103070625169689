import { Controller } from 'stimulus'
import { MDCTextFieldIcon } from '@material/textfield/icon'

export default class extends Controller {
  static targets = [ 'text', 'button']

  connect() {
    MDCTextFieldIcon.attachTo(this.buttonTarget)
  }

  copyText(event){
    this.textTarget.select()
    document.execCommand('copy')
    document.getSelection().removeAllRanges()
  }
}

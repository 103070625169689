import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const pathname = window.location.pathname
    const items = this.element.querySelectorAll('.mdc-list-item')

    var activated = null
    for (var item of items) {
      if (pathname.includes(item.getAttribute('href'))) {
        activated = item
      }
    }
    (activated || items[0]).classList.add('mdc-list-item--activated')
  }
}

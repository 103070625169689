import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "source" ]

  copy(event) {
    event.preventDefault();
    const elm = this.sourceTarget;
    var selection, range;
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(elm);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
  }
}

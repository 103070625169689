import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const textArea = this.element.querySelector('textarea');
    textArea.addEventListener('mouseup', this.checkSelection);
  }

  checkSelection(event) {
    const textArea = event.target;
    var selectedText = '';
    if (textArea.selectionStart != textArea.selectionEnd) {
      var startPos = textArea.selectionStart;
      var endPos = textArea.selectionEnd;
      selectedText = textArea.value.substring(startPos, endPos);
      const selected = document.getElementById('highlight-selected');
      if (selected) {
        selected.innerText = "You selected: " + selectedText + " (" + startPos + " - " + (endPos - 1) + ")";
      }
    }
  }

  selectText(start, end) {
    const textArea = this.element.querySelector('textarea');
    textArea.setSelectionRange(start, end);
  }
}

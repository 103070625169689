import { Controller } from 'stimulus'
import { MDCTextFieldIcon } from '@material/textfield/icon'

export default class extends Controller {
  static targets = [ 'input', 'button', 'text']

  connect() {
    MDCTextFieldIcon.attachTo(this.buttonTarget)
  }

  toggle(){
    const deleting = this.inputTarget.value == '0'
    this.inputTarget.value = deleting ? '1' : '0'
    this.element.classList.toggle('cms-will-deleted', deleting)
    this.textTarget.classList.toggle('strike', deleting)

    this.buttonTarget.textContent = deleting ? 'redo' : 'backspace'
    this.buttonTarget.title = deleting ? 'Cancel removal' : 'Remove file'
  }
}

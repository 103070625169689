import { Controller } from 'stimulus'

export default class extends Controller {
  success(e) {
    const row = e.target.parentElement.parentElement
    const body = e.detail[2].response

    row.outerHTML = body
  }

  error() {
    alert('Could not save!')
  }
}

import { Controller } from 'stimulus'
import { MDCTextField } from '@material/textfield'

export default class extends Controller {
  static targets = [ 'item', 'list' ]
  connect() {
    window.app = this.appliction;
    window.controller = this;
    //this.application.getControllerForElementAndIdentifier(this.element, "list")
    this.itemTargets.forEach((item, idx) => {
      item.querySelector('[data-attr="number"]').innerText = idx + 1

      item.querySelectorAll('input, textarea').forEach((element) => {
        element.addEventListener('change', this.checkIntervals);
        element.addEventListener('focus', this.showSelection);
      })
    })
  }

  add() {
    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    const clone = lastItem.cloneNode(true)

    clone.querySelectorAll('input, textarea').forEach((element) => {
      const name = element.name
      if (name){
        element.name = name.replace(/\[highlights_attributes\]\[(\d+)\]/, (_, number) => `[highlights_attributes][${parseInt(number) + 1}]` )
      }
      const id = element.id
      if (id) {
        element.id = id.replace(/_highlights_attributes_(\d+)/, (_, number) => `_highlights_attributes_${parseInt(number) + 1}` )
      }
      element.value = ''
    })

    const number = clone.querySelector('[data-attr="number"]')
    number.innerText = this.itemTargets.length + 1

    this.listTarget.appendChild(clone)

    clone.querySelectorAll('.mdc-text-field').forEach((element) => {
      MDCTextField.attachTo(element)
    })
  }

  checkIntervals(event) {
    const elm = event.target;
    const value = elm.value;
    if (!value) {
      return;
    }
    if (isNaN(value)) {
      alert('Must be a number.');
      return;
    }
  }

  showSelection(event) {
    const elm = event.target;
    var start = 0, end = 0;
    if (elm.name.indexOf('_end') > 0) {
      start = document.getElementById(elm.id.replace('_end', '_start')).value;
      end = elm.value;
    } else {
      start = elm.value;
      end = document.getElementById(elm.id.replace('_start', '_end')).value;
    }
    if (!(start && end) || isNaN(start) || isNaN(end)) {
      return;
    }
    const textArea = document.getElementById('activity_writing_attributes_model_answer');
    if (!textArea) {
      return;
    }
    var selection = textArea.value.substring(start, parseInt(end) + 1);
    if (!selection) {
      return;
    }
    const selected = document.getElementById('highlight-selected');
    if (selected) {
      selected.innerText = 'You selected: ' + selection;
    }
  }
}

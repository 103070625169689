import { Controller } from 'stimulus'
import { MDCMenu, Corner } from '@material/menu'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'menu' ]

  connect() {
    this.menu = new MDCMenu(this.menuTarget)
    this.menu.setFixedPosition(true)
    this.menu.setAnchorCorner(Corner.TOP_START)
  }

  open() {
    this.menu.open = true
  }
}

import { Controller } from 'stimulus'
import { MDCDialog } from '@material/dialog'

export default class extends Controller {
  static targets = [ 'form', 'dialog']

  connect() {
    this.dialog = new MDCDialog(this.dialogTarget)
  }

  submit(event) {
    if (event.detail.action === 'accept') {
      this.formTarget.submit()
    }
  }

  open() {
    this.dialog.open()
  }
}

class FormUtil {
  static setIndexForNames(element, attr) {
    const name = element.name
    if (name){
      element.name = name.replace(
        RegExp(`\\[${attr}\\]\\[(\\d+)\\]`),
        (_, number) => `[${attr}][${parseInt(number) + 1}]`
      )
    }
  }

  static setIndexForIds(element, attr) {
    const id = element.id
    if (id) {
      element.id = id.replace(
        RegExp(`_${attr}_(\\d+)`),
        (_, number) => `_${attr}_${parseInt(number) + 1}`
      )
    }
  }

  static setIndexForIdsAndNames(element, attr) {
    this.setIndexForNames(element, attr)
    this.setIndexForIds(element, attr)
  }
}

export { FormUtil }

import { Controller } from 'stimulus'
import { MDCRipple } from '@material/ripple'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.mdc-icon-button').forEach(icon => {
      const ripple = new MDCRipple(icon)
      ripple.unbounded = true
    })
  }
}

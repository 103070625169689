import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const form = this.element
    const textArea = form.querySelector('textarea[name*="body"]')
    const file = form.querySelector('input[type="file"]')

    if (file && textArea) {
      function checkEmbed(text) {
        var match, reg = /{{{(\d+)?\s*("?file\/([^\"]+)"?)?(\s+class="[^"]+")?}}}/g
        while (match = reg.exec(text)) {
          var val = match[3] || match[1]
          var attachment = form.querySelector('[value="'+val+'"]')
          if (!attachment) {
            alert(match[0] + " is missing, please upload the file.")
          }
        }
      }

      function checkBrackets(expr){
        const holder = []
        const openBrackets = ['{']
        const closedBrackets = ['}']
        for (let letter of expr) { // loop trought all letters of expr
          if(openBrackets.includes(letter)){ // if its oppening bracket
            holder.push(letter)
          }else if(closedBrackets.includes(letter)){ // if its closing
            const openPair = openBrackets[closedBrackets.indexOf(letter)] // find his pair
            if(holder[holder.length - 1] === openPair){ // check if that pair is last element in array
              holder.splice(-1,1) //if so, remove it
            }else{ // if its not
                holder.push(letter)
                break // exit loop
            }
          }
        }
        return (holder.length === 0) // return true if length is 0, otherwise false
      }

      function checkTextArea(event) {
        const text = textArea.value
        if (!checkBrackets(text)) {
          alert('Please check brackets {{{id}}}.')
        }
        checkEmbed(text);

      }

      textArea.addEventListener('change', checkTextArea)
    }
  }
}

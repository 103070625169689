import { Controller } from 'stimulus'
import { MDCDataTable } from '@material/data-table'
import { MDCTextField } from '@material/textfield'
import { MDCSelect } from '@material/select'
import { MDCRipple } from '@material/ripple'

export default class extends Controller {
  static targets = [ 'table' ]

  connect() {
    this.element.querySelectorAll('.mdc-text-field').forEach((element) => {
      MDCTextField.attachTo(element)
    })
    this.element.querySelectorAll('.mdc-select').forEach((element) => {
      MDCSelect.attachTo(element)
    })

    MDCRipple.attachTo(this.element.querySelector('.mdc-button'))

    MDCDataTable.attachTo(this.tableTarget)
  }

}

import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const message = 'Do you want to leave the page? Changes you made may not be saved.'
    const form = this.element

    function checkChanges(event) {
      const changed = form.querySelectorAll('[data-changed="true"]')
      if (changed.length) {
        if (confirm(message)) {
          changed.forEach((input) => {
            input.dataset.changed = 'ignore'
          })
        } else {
          event.preventDefault()
          event.returnValue = message
        }
      }
    }

    window.addEventListener('beforeunload', checkChanges)
    document.addEventListener('turbolinks:before-visit', checkChanges)

    form.addEventListener('submit', () => {
      window.removeEventListener('beforeunload', checkChanges)
      document.removeEventListener('turbolinks:before-visit', checkChanges)
    })

    form.querySelectorAll('input, select, textarea').forEach((input) => {
      input.addEventListener('change', () => {
        input.dataset.changed = 'true'
      })
    })
  }
}

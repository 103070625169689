import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'training']

  connect() {
    this.toggleTraining()
  }

  typeChosen(event) {
    this.data.set('type', event.target.value)
    this.toggleTraining()
  }

  toggleTraining() {
    const input = this.trainingTarget.querySelector('input')

    if (this.data.get('type') == 'tutored') {
      this.trainingTarget.classList.remove('mdc-text-field--disabled')
      this.trainingTarget.querySelector('input').disabled = false
      if (this.trainingTarget.dataset.value) {
        input.value = this.trainingTarget.dataset.value
      }
    } else {
      this.trainingTarget.classList.add('mdc-text-field--disabled')
      if (input.value) {
        this.trainingTarget.dataset.value = input.value
        input.value = ''
      }
    }
  }
}

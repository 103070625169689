import { Controller } from 'stimulus'
import { MDCSnackbar } from '@material/snackbar'

export default class extends Controller {
  connect() {
    const snackbar = new MDCSnackbar(this.element)
    snackbar.timeoutMs = 7500
    snackbar.open()
  }
}
